.mint-page {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 20px;
  .info-box {
    border-radius: 8px;
    border: 1px solid rgba(44, 35, 27, 0.2);
    background: #fff;
    margin-top: 100px;
    padding: 24px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 530px) {
      padding: 14px;
      margin-top: 80px;
    }

    .info-item {
      width: 33.333%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      @media (max-width: 530px) {
        width: max-content;
        align-items: center;
      }
      .title-info {
        color: rgba(44, 35, 27, 0.8);
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        text-transform: uppercase;
        opacity: 0.7;
        @media (max-width: 530px) {
          font-size: 12px;
        }
      }
      .value-info {
        color: #2c231b;
        font-variant-numeric: lining-nums proportional-nums;

        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 8px;

        @media (max-width: 530px) {
          font-size: 14px;
        }
      }
    }
  }
  .mint-box {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .charities-box {
      .charities-section {
        padding-bottom: 50px;
        margin-bottom: 0;
        border-bottom: 1px solid rgba(44, 35, 27, 0.2);
        @media (max-width: 530px) {
          padding-bottom: 30px;
        }
        .charities-title {
          color: #2c231b;
          font-variant-numeric: lining-nums proportional-nums;
          font-size: 17px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 16px;
        }
        .charity {
          img {
            width: 60px;
            height: 60px;
          }
          h3 {
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
          }
        }
      }
    }
  }

  .mint-section {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 530px) {
      padding-top: 30px;
    }

    .main-button {
      font-size: 19px;
      padding: 12px 20px;
      margin-bottom: 16px;
    }
    .mint-img {
      height: 238px;
      width: auto;
      margin-bottom: 24px;
    }
    h3 {
      color: rgba(44, 35, 27, 0.8);
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 105.882% */
      text-transform: uppercase;
      opacity: 0.7;
      margin-bottom: 4px;
    }
    p {
      color: #2c231b;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .controls-input {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-bottom: 16px;

      .decrease {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        border-radius: 100px;
        border: 1px solid rgba(44, 35, 27, 0.2);
        color: #2c231b;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        &:hover {
          box-shadow: 0px 0px 10px rgba(44, 35, 27, 0.2);
        }

        &:disabled {
          box-shadow: none;
          cursor: not-allowed;
          opacity: 0.7;
        }
      }

      .mint-amount {
        color: #2c231b;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    .verify-transaction {
      color: rgba(44, 35, 27, 0.6);
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 10px;
      a {
        color: #f42921;
        text-decoration-line: underline;
      }
    }
  }

  .faq-box {
    align-items: flex-start;
    margin-bottom: 50px;
    .title {
      color: #2c231b;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 16px;
    }

    .desctiption {
      color: #2c231b;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 0.8;
      margin-bottom: 40px;
      .sub-title {
        margin-bottom: 10px;
        font-weight: 700;
      }
      a {
        color: #f42921;
        text-decoration-line: underline;
      }

      .last-step {
        margin-top: 16px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
