.nft-card-box {
  border: 0.54px solid #00000033;
  padding: 20px 0px 0px 0px;
  gap: 12px;
  border-radius: 8px;
  padding: 15px;
  background: #ffffff;
  width: 250px;
  box-shadow: 0px 0px 10px -4px #181818;

  @media (max-width: 1340px) {
    width: calc(25% - 9px);
  }
  @media (max-width: 1050px) {
    width: calc(33.3333% - 8px);
  }
  @media (max-width: 767px) {
    width: calc(50% - 8px);
  }
  @media (max-width: 550px) {
    width: calc(70%);
  }
  @media (max-width: 430px) {
    width: calc(100%);
  }

  .img-box {
    width: 100%;
    border-radius: 4.29px;
    background: #876d4e;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
    position: relative;
    // @media (max-width: 550px) {
    //   height: 268px;
    // }

    img {
      height: 100%;
      width: 100%;
    }

    .img-price {
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 2px 5px;
      border-radius: 5px;
      border: 1.5px solid rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      gap: 2px;
      font-family: Source Code Pro;
      font-size: 12.87px;
      font-weight: 400;
      line-height: 16.17px;
      text-align: left;
      color: #ffffff;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  .nft-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    h3 {
      font-size: 16.08px;
      font-weight: 700;
      line-height: 21.71px;
      text-align: left;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 16.2px;
      text-align: left;
      opacity: 0.6;
    }
    .price-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 17.55px;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 4px;
      }
      button {
        padding: 6px 12px;
        gap: 8px;
        border-radius: 100px;
        background: #000000;
        border: 0.5px solid #000000;
        color: #ffffff;
        font-family: "Mulish", sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 16.32px;
        text-align: left;
        transition: all 0.1s ease;

        &:hover {
          background: #ffffff;
          color: #000000;
        }
      }
    }
  }
}
