.charities-section {
  width: 100%;
  margin-bottom: 150px;
  @media (max-width: 767px) {
    margin-bottom: 100px;
  }
  @media (max-width: 550px) {
    margin-bottom: 60px;
  }

  .charities-title {
    color: #000;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: "Mulish", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }
  .charities {
    display: flex;
    flex-wrap: wrap;
    flex-basis: auto;
    gap: 15px;

    @media (max-width: 1050px) {
      gap: 10px;
    }
    .charity {
      width: calc(33.33333% - 10px);
      border-radius: 8px;
      border: 1px solid rgba(44, 35, 27, 0.2);
      background: #fff;
      padding: 12px;
      display: flex;
      gap: 20px;
      align-items: center;
      cursor: pointer;
      transition: all 0.1s ease;

      @media (max-width: 1050px) {
        gap: 10px;
        width: calc(33.33333% - 7px);
      }
      @media (max-width: 950px) {
        width: calc(50% - 6px);
      }
      @media (max-width: 600px) {
        width: calc(100%);
      }

      &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid #2c231b;
      }
      img {
        width: 100px;
        height: 100px;
        @media (max-width: 650px) {
          width: 70px;
          height: 70px;
        }
      }
      h3 {
        color: #2c231b;
        text-align: center;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: "Mulish", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        @media (max-width: 1050px) {
          font-size: 16px;
        }

        .selected-indicator {
          border-radius: 100px;
          background: rgba(44, 35, 27, 0.1);
          display: flex;
          padding: 2px 8px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          color: #2c231b;
          font-size: 11px;
          font-weight: 400;
          width: max-content;
          margin-top: 4px;
        }
      }

      &.selected {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid #2c231b;
      }
    }
  }
}
