.about-page {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;

  .content-box {
    padding-top: 120px;

    @media (max-width: 1340px) {
      padding-top: 120px;
    }
    @media (max-width: 600px) {
      padding-top: 100px;
    }
    @media (max-width: 450px) {
      padding-top: 80px;
    }
  }

  h1 {
    margin-top: 100px;
  }

  ul {
    list-style: circle;
    margin-left: 20px;
  }

  .title-text a {
    color: red;
  }
}
